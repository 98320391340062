@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,600;0,700;1,500&display=swap');

* {
  padding: 0;
  margin: 0;
  list-style-type: none;
  text-decoration: none;
  scroll-behavior: smooth;
  box-sizing: border-box;
  font-family: 'Lora', serif;
}
body {
  background-color: #f8fbff;
}
img {
  cursor: pointer;
}
.fs_14 {
  font-size: 14px;
}
.fs_15 {
  font-size: 15px;
}
.fs_16 {
  font-size: 16px;
}
.fs_17 {
  font-size: 17px;
}
.fs_18 {
  font-size: 18px;
}
.fs_19 {
  font-size: 19px;
}
.fs_19 {
  font-size: 19px;
}
.fs_20 {
  font-size: 20px;
}
.fs_22 {
  font-size: 22px;
}
.fs_24 {
  font-size: 24px;
}
.fs_26 {
  font-size: 26px;
}
.fs_28 {
  font-size: 28px;
}
.fs_39 {
  font-size: 39px;
}

.fw_400 {
  font-weight: 400;
}
.fw_500 {
  font-weight: 500;
}
.fw_600 {
  font-weight: 600;
}
.fw_700 {
  font-weight: 700;
}
.black {
  color: #000000;
}
ul {
  padding: 0 !important;
  margin: 0 !important;
}
p {
  margin: 0 !important;
}

.w_150 {
  width: 150px;
}

.border_black {
  border: 1px solid black;
}
.border_top_black {
  border-top: 1px solid black;
}
.border_bottom_black {
  border-top: 1px solid black;
}
.border_right_gray {
  border-right: 1px solid gray;
}
.bg_light_gray {
  background-color: rgb(229, 229, 229);
}

.black_underline {
  border-bottom: 3px solid black;
  display: inline-block;
  line-height: 15px;
}
.w_h_30px {
  width: 30px;
  height: 30px;
  border: 0;
  outline: 0;
}

.border_right_black {
  border-right: 1px solid black;
}
.bg_gray {
  background-color: rgb(210, 207, 207);
}
.container2 {
  max-width: 1320px;
  padding-left: 12px;
  padding-right: 12px;
  margin: 0 auto;
  box-shadow: 0px 0px 16px -6px black;
}
@media screen and (max-width: 991px) {
  /* For tablet and below */
  .container2 {
    box-shadow: none; /* Remove box-shadow */
  }
}
input {
  outline: 0;
  border: 0;
  border: 1px solid black;
  margin-left: 8px !important;
  padding-left: 8px;
  padding-right: 8px;
}
.rs-input-error {
  border-color: #f44336 !important;
}
.rs-input-edit {
  margin-top: -5px !important;
}
.w_500 {
  width: 100%;
}
.w_400 {
  width: 400px;
}
.white_space_no_wrap {
  white-space: nowrap;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  border: 1px solid black !important;
  padding: 8px 20px;
}
table {
  width: 100%;
}

.bg_yellow {
  background-color: yellow;
}

p {
  font-size: 16px;
  font-weight: 400;
  color: black;
}

th {
  font-weight: 600;
  font-size: 16px;
  color: black;
}
td {
  font-weight: 400;
  font-size: 16px;
  color: black;
}
span {
  font-weight: 600;
}

.form_container {
  max-width: 770px;
  margin: 0px auto;
  box-shadow: 0px 16px 48px 0px #0000002d;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
h1,
h3,
p {
  margin-bottom: 0 !important;
}
input {
  border: 0;
  outline: 0;
}
.input_decoration {
  padding: 21px 22px;
  border-radius: 32px;
  background-color: #ffffff01;
  box-shadow: 0px 16px 48px 0px #0000002d;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
::placeholder {
  color: #757575;
}

.color_blue_black {
  color: #212529;
}
.color_red {
  color: #ff4545;
}

.form_width {
  max-width: 502px;
  margin: 0 auto;
}
.logo {
  height: 150px;
  width: 150px;
}

.white-space-nowrap {
  white-space: nowrap;
}

.sign_in_btn {
  border: 0;
  outline: 0;
  background-color: #2e74c7;
  border-radius: 32px;
  padding: 18px;
  box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
}
.sign_in_btn:hover {
  box-shadow: 0 0px 0px 0 rgba(49, 196, 190, 0.75);
}
.chart-flex {
  display: flex;
}
.button-flex {
  display: flex;
}
@media (max-width: 576px) {
  .chart-flex {
    display: flex;
    flex-direction: column;
  }
  .button-flex {
    flex-direction: column;
  }
  .fs_39 {
    font-size: 30px;
  }
  .fs_24 {
    font-size: 18px;
  }
  .fs_16 {
    font-size: 14px;
  }
  .fs_20 {
    font-size: 17px;
  }
  .input_decoration {
    margin-top: 35px;
    padding: 15px 18px;
  }
}

/* //  section-two  // */

.section-two {
  height: 100vh;
}
.rs-pagination-group-skip span {
  margin-right: 5px;
}
aside {
  height: 100px;
  background: #fff;
  box-shadow: 0px 22px 100.8px 0px rgba(0, 0, 0, 0.04);
}

.table-section {
  background-color: #f8fbff;
}

.table {
  background-color: #ffffff;
  border-radius: 30px;
  height: 100%;
}

/* //  Login  // */

.side_bg {
  background: linear-gradient(178deg, #a66ccd, #622de0, #0b0cf7);
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.card {
  overflow: hidden;
  border-radius: 26px;
  background: #fff;
  box-shadow: 5px 4px 36.4px 0px rgba(0, 0, 0, 0.1);
  border: none;
}

.img-area {
  height: 267px;
  width: 100%;
  position: relative;
}

.play-btn {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
}

.card .description-area {
  padding: 15px 20px;
}
.text_red {
  color: red;
}
.bg_green {
  background-color: rgb(12, 245, 12);
}
.w_15 {
  width: 15%;
}
.w_33 {
  width: 33%;
}

.bg_light_green {
  background-color: rgb(141, 190, 141);
}

.bg_light_pink {
  background-color: rgb(209, 119, 134);
}

.bg_pink {
  background-color: pink;
}

.bg_light_antiquewhite {
  background-color: rgb(204, 143, 178);
}

.bg_light_khaki {
  background-color: rgb(200, 195, 154);
}

.bg_khaki {
  background-color: khaki;
}

.bg_purple {
  background-color: rgb(164, 111, 164);
}

.bg_dark_darkolivegreen {
  background-color: darkolivegreen;
}

.w_300 {
  width: 300px;
}

.text_vertical {
  writing-mode: vertical-lr;
}
.login-vh {
  min-height: 100vh;
}
.input-error {
  border: 1px solid #ff4545;
}
.pointer {
  cursor: pointer;
}
.table-heading {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
  height: 40px !important;
  border-right: 1px solid #f2f2f5;
  border-left: 1px solid #f2f2f5;
  border-top: 1px solid #f2f2f5;
  background: #2e74c7;
  font-weight: 600;
}
.table-heading2 {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
  height: 40px !important;
  border-right: 1px solid #f2f2f5;
  border-left: 1px solid #f2f2f5;
  border-top: 1px solid #f2f2f5;
  background: #8fd24d;
  font-weight: 600;
}
.rs-input {
  margin-left: 0px !important;
}
/* Form */

.static-buttons > a {
  background-color: #2e74c7;
  border-radius: 32px;
  padding: 9px 20px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  margin: 10px;
  box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
}
.static-buttons > a:hover {
  box-shadow: 0 0px 0px 0 rgba(49, 196, 190, 0.75);
  background-color: #2e74c7;
  color: #fff;
}

.static-buttons {
  position: fixed;
  bottom: 30px;
  right: 10%;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #fff;
}
::-webkit-scrollbar {
  border-radius: 10px;
  width: 6px;
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  background-color: #2e74c7;
}

.rs-table-column-group-header-content {
  /* Your styling here */
  color: black !important;
  font-weight: bold !important;
}

@media (max-width: 512px) {
  .static-buttons {
    left: 10%;
  }
}
@media (max-width: 700px) {
  .responsive-parent th span {
    font-size: 10px;
    line-height: 5px;
  }
  .responsive-parent th {
    font-size: 10px;
  }

  .responsive-parent td {
    font-size: 10px;
  }
  .double-input input {
    width: 50px;
  }

  .single-input p {
    font-size: 10px;
  }
  .single-input input {
    width: 70px;
  }
}
