/* .form_table {
    height: 100vh;
    overflow-y: hidden;
} */

.home_sec {
  background-color: #fff;
}

.home_sec .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
}

.home_sec .header .btn:global(.btn) {
  background-color: #2e74c7;
  border-radius: 32px;
  padding: 9px 20px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  margin: 10px;
  box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
}
.user-ava{
  background-color: #2e74c7;
  border-radius: 32px;
  padding: 9px 20px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  margin: 10px;
  box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
}
.home_sec .header .btn:hover {
  box-shadow: 0 0px 0px 0 rgba(49, 196, 190, 0.75);
}
.home_sec .image {
  width: 90px;
  height: 90px;
  margin: auto 0;
}

.table_body {
  background-color: #f8fbff;
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
  /* height: 91vh; */
}

/* table style */

.serial {
  font-size: 13px;
  font-weight: 400;
  color: #667085;
}
.topic {
  font-size: 13px;
  font-weight: 400;
  color: #667085;
}
.link a {
  font-size: 13px;
  font-weight: 400;
  color: #667085;
}
